/* eslint-disable jsx-quotes */
import { loadNewsHome } from '@/redux/actions/news';
import { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { getExchangeRate } from '../../redux/actions/payment';
import { parseVideo } from '../../utils/currency';
import Device from '../Device';
import { getAllStore } from '@/redux/actions/store';
import { actionSaveShowPopupLogin } from '@/redux/actions/auth';
import { loadListProduct } from '@/redux/actions/product';
import { getRefInfo } from '@/redux/actions/user';
import BreadcrumbList from '../BreadcrumbList';
import MetaSeo from '../MetaSeo';
import useTrans from '@/hooks/useTrans';
import { useRouter } from 'next/router';
import 'swiper/css';
import 'swiper/css/pagination';
import AppDownload from '../Common/AppDownload';
import moment from 'moment';

export default function Home(props) {
  const ref_play = useRef();
  const router = useRouter();
  const trans = useTrans();
  const {
    id_ref,
    title,
    seo_title,
    seo_image,
    seo_description,
    data_banner,
    data_social,
    data_video,
    partner_list,
    list_blog
  } = props;

  const dispatch = useDispatch();
  const [selectStore, setSelectStore] = useState(null);
  const [urlMap, setUrlMap] = useState('');
  const [checkC, setCheckC] = useState(0);
  const [gold, setGold] = useState(1);
  const [money, setMoney] = useState(0);
  const [video, setVideo] = useState([]);
  const [listHeader, setListHeader] = useState([
    {
      title: trans.home.se_h_a,
      isA: true,
    },
    {
      title: trans.home.se_h_b,
      isA: false,
    },
    {
      title: trans.home.se_h_c,
      isA: false,
    },
  ]);

  useEffect(() => {
    setListHeader([
      {
        title: trans.home.se_h_a,
        isA: true,
      },
      {
        title: trans.home.se_h_b,
        isA: false,
      },
      {
        title: trans.home.se_h_c,
        isA: false,
      },
    ]);
  }, [router?.locale]);

  const [check, setCheck] = useState(0);
  const [checkPartner, setCheckPartner] = useState(0);
  const [listPart, setListPart] = useState({});

  const { exchangeRate, social } = useSelector(state => {
    return {
      exchangeRate: state.payment.exchangeRate,
      social: state.layout.social,
    };
  }, shallowEqual);

  const { store } = useSelector(state => {
    return {
      news: state.news.news,
      products: state.product.listProduct,
      store: state.store.listStore,
    };
  }, shallowEqual);

  useEffect(() => {
    (async () => {
      dispatch(loadListProduct());
      loadNewsHome({}, dispatch);
      getExchangeRate({}, dispatch);
      (async () => {
        const data = await getAllStore({}, dispatch);
        if (data && data.length) {
          setSelectStore(data[0].id);
          setUrlMap(data[0].link_iframe);
        }
        if (id_ref) {
          localStorage.setItem('refs', id_ref);
          getRefInfo({ id_ref }, dispatch);
          dispatch(actionSaveShowPopupLogin(2));
        } else {
          const refs = localStorage.getItem('refs');
          if (refs) {
            getRefInfo({ id_ref: refs }, dispatch);
          }
        }
      })();
    })();
  }, [dispatch]);

  const obj_social = {};
  social.map(social => {
    obj_social[social.config_key] = social.config_value;
  });

  const calculateGoldToMoney = gold => {
    let goldConvert = gold?.replace(/,/g, '-');
    gold = goldConvert?.replace(/-/g, '.');
    gold =
      Number(gold)
        .toFixed(3)
        .replace(',', '')
        .replace('.', '') / 1000;

    return gold * exchangeRate?.buy_price;
  };

  useEffect(() => {
    setMoney(calculateGoldToMoney('1'));
  }, [exchangeRate]);

  const calculateMoneyToGold = money => {
    money =
      Number(money)
        .toFixed(3)
        .replace(',', '')
        .replace('.', '') / 1000;

    return Number(money / (exchangeRate?.buy_price || 1)).toFixed(3) || 0;
  };

  const onClickStore = store => {
    setSelectStore(store.id);
    setUrlMap(store.link_iframe);
  };

  const dataBreadcrumbList = [
    {
      name: 'Home',
      '@id': '/',
    },
    {
      name: 'About',
      '@id': '/#about',
    },
    {
      name: 'Product',
      '@id': '/product',
    },
    {
      name: 'Trang sức Happy Stone',
      '@id': '/trang-suc-happy-stone',
    },
    {
      name: 'Đồng Vàng',
      '@id': '/phoenix',
    },
    {
      name: 'Đối tác nhượng quyền',
      '@id': '/nhuong-quyen-kinh-doanh-vang-partners',
    },
    {
      name: 'Liên hệ',
      '@id': '/#contact',
    },
    { name: 'Tin tức', '@id': '/tin-tuc-su-kien' },
    { name: 'Hướng dẫn mua hàng', '@id': '/huong-dan-mua-hang' },
    { name: 'Hướng dẫn thanh toán', '@id': '/tutorial/payment' },
    { name: 'Hướng dẫn bảo quản trang sức', '@id': '/huong-dan-bao-quan-trang-suc' },
    { name: 'Hướng dẫn đo size trang sức', '@id': '/huong-dan-cach-do-size-trang-suc' },
    { name: 'Hướng dẫn giao nhận hàng', '@id': '/huong-dan-giao-nhan-hang' },
    { name: 'Công bố tiêu chuẩn cơ sở', '@id': '/cong-bo-tieu-chuan-co-so' },
    { name: 'Quy định bảo hành', '@id': '/quy-dinh-bao-hanh' },
    { name: 'Chính sách bảo mật', '@id': '/security' },
    { name: 'Điều khoản dịch vụ', '@id': '/policy' },
  ];

  const dataSeo = {
    title:
      seo_title || title || 'HanaGold - Đầu tư và tích lũy vàng công nghệ NFC',
    image: seo_image || 'https://hanagold.vn/assets/img/home/h_seo.png?t=1',
    description: seo_description || '',
    url: id_ref ? `https://hanagold.vn/${id_ref}` : 'https://hanagold.vn'
  };

  const setting_a = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    customPaging: () => {
      return <span></span>;
    },
    dotsClass: 'slick-thumb-dot slick_banner',
  };

  const handleGetPartner = (arr, idx = 0) => {
    if (arr?.length > 0) {
      setCheckPartner(idx);
      setListPart(arr[idx]);
    }
  };

  const setting_p = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <div className="controls_s">
        <img src="/assets/img/home/next_s.svg" alt="" />
      </div>
    ),
    prevArrow: (
      <div className="controls_s">
        <img src="/assets/img/home/pre_s.svg" alt="" />
      </div>
    ),
    customPaging: () => {
      return <span></span>;
    },
    afterChange: idx => {
      handleGetPartner(partner_list, idx);
    },
  };

  let settingNews = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: list_blog?.rows?.length <= 3 ? list_blog?.rows?.length : 3,
    slidesToScroll: 1,
    customPaging: () => {
      return <span></span>;
    },
    nextArrow: (
      <div className="controls_s">
        <img src="/assets/img/home/next_s.svg" alt="" />
      </div>
    ),
    prevArrow: (
      <div className="controls_s">
        <img src="/assets/img/home/pre_s.svg" alt="" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 725,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setting_b = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data_social?.length <= 5 ? data_social?.length : 5,
    slidesToScroll: 1,
    customPaging: () => {
      return <span></span>;
    },
    nextArrow: (
      <div className="controls_s">
        <img src="/assets/img/home/next_s.svg" alt="" />
      </div>
    ),
    prevArrow: (
      <div className="controls_s">
        <img src="/assets/img/home/pre_s.svg" alt="" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 725,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setting_c = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <div className="controls_s">
        <img src="/assets/img/home/next_s.svg" alt="" />
      </div>
    ),
    prevArrow: (
      <div className="controls_s">
        <img src="/assets/img/home/pre_s.svg" alt="" />
      </div>
    ),
    customPaging: () => {
      return <span></span>;
    },
    afterChange: index => {
      setCheck(index);
      formatVideo(null);
    },
    dotsClass: 'slick-thumb-dot yb_dot',
  };

  const selectTab = index => {
    const new_arr = listHeader?.map((data, idx) => {
      if (idx === index) {
        return {
          ...data,
          isA: true,
        };
      }
      return {
        ...data,
        isA: false,
      };
    });
    setListHeader(new_arr);
    setCheckC(index);
  };

  const formatVideo = idx => {
    // setVideo(data_video);
    const data = data_video?.map((value, index) => {
      if (idx === null) {
        return {
          ...value,
          isPlay: false,
        };
      }
      if (idx || idx === 0) {
        if (index === idx) {
          return {
            ...value,
            isPlay: true,
          };
        }
        return {
          ...value,
          isPlay: false,
        };
      }
    });

    setVideo(data);
  };
  useEffect(() => {
    formatVideo(null);
  }, []);

  useEffect(() => {
    handleGetPartner(partner_list);
  }, [partner_list]);

  const setState = show_id => {
    dispatch(actionSaveShowPopupLogin(show_id));
  };

  return (
    <>
      <MetaSeo {...dataSeo} />
      <BreadcrumbList posts={dataBreadcrumbList} articles="" />
      <Device>
        {({ isMobile }) => (
          <div
            className={
              (isMobile ? 'web-mobile' : 'web-pc') +
              (isMobile ? ' disable-wow' : '')
            }
          >
            <div className="home_slides_dt">
              <div className="home_slides_container">
                <div className="home_slides_content">
                  {data_banner?.length > 0 && (
                    <Slider {...setting_a}>
                      {data_banner?.map((value, idx) => {
                        return (
                          <div
                            className="h_img_slide"
                            key={idx}
                            onClick={() => {
                              if (value?.link) {
                                window.open(value?.link);
                              }
                            }}
                          >
                            <div
                              style={{
                                backgroundImage: `url(${value.image})`,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </Slider>
                  )}
                </div>
              </div>
            </div>

            <div className="home_slides_mb">
              <div className="home_slides_container">
                <div className="home_slides_content">
                  {data_banner?.length > 0 && (
                    <Slider {...setting_a}>
                      {data_banner?.map((value, idx) => {
                        return (
                          <div className="h_img_slide">
                            <div
                              key={idx}
                              style={{
                                backgroundImage: `url(${value?.image_mobile})`,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </Slider>
                  )}
                </div>
              </div>
            </div>

            <div className="h_trans">
              <div className="h_trans_container" data-aos="fade-down">
                <div className="h_trans_content">
                  <h2>{trans.home.trans}</h2>
                  <div>
                    <div className="h_t_item">
                      <span>{trans.home.trans_a}</span>
                      <div>
                        <CurrencyInput
                          decimalsLimit="3"
                          decimalSeparator=","
                          groupSeparator="."
                          type="text"
                          value={gold || 0}
                          onValueChange={(value, name) => {
                            setGold(value || 0);
                            setMoney(calculateGoldToMoney(value || '0'));
                          }}
                          aria-label="gold"
                        />

                        <img src="/assets/img/home/ic_gold.png" alt="" />
                        <span>{trans.home.trans_b}</span>
                      </div>
                    </div>
                    <img src="/assets/img/home/to.svg" alt="" />
                    <div className="h_t_item">
                      <span>{trans.home.trans_c}</span>
                      <div>
                        <CurrencyInput
                          decimalsLimit="0"
                          decimalSeparator=","
                          groupSeparator="."
                          type="text"
                          value={money || 0}
                          onValueChange={(value, name) => {
                            setMoney(value || 0);
                            setGold(calculateMoneyToGold(value || 0));
                          }}
                          aria-label="currency"
                        />
                        <img src="/assets/img/home/ic_vnd.png" alt="" />
                        <span>{trans.home.trans_d}</span>
                      </div>
                    </div>
                  </div>

                  <div className="h_more_gold">
                    {/* <a href="/gia-vang" target="_blank">
                      {trans.home.trans_e}
                    </a> */}
                    <a href="/gia-vang" target="_blank" className="btn_a_home">
                      <img src="/assets/img/home/next.svg" alt="" />
                      <p>{trans.home.read_more}</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="home_so">
              <div className="home_so_container">
                <div className="home_so_content">
                  <h2 data-aos="fade-down">{trans.home.so}</h2>
                  <p
                    className="h_text_mb"
                    data-aos="fade-down"
                    dangerouslySetInnerHTML={{
                      __html: trans.home.so_a,
                    }}
                  ></p>

                  <p
                    className="h_text_dt"
                    data-aos="fade-down"
                    dangerouslySetInnerHTML={{
                      __html: trans.home.so_b,
                    }}
                  ></p>

                  <div className="home_so_block_all">
                    <div className="home_so_block">
                      <div data-aos="fade-right">
                        <img src="/assets/img/home/so_a.png?t=2" className='lazyload' alt="" style={{width: "100%", height: "100%"}}/>
                      </div>
                      <div data-aos="fade-left">
                        <div>
                          <h3>{trans.home.so_t_a}</h3>
                          <div>
                            <a href="/app-mua-vang-online" target="_blank">
                              {trans.home.read_more}
                            </a>
                            <img src="/assets/img/home/next_m.svg?t=1" alt={trans.home.so_t_a} />
                          </div>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: trans.home.so_d_a,
                          }}
                        ></p>
                        <a href="/app-mua-vang-online" target="_blank">
                          <img src="/assets/img/home/next.svg" alt="" className='lazyload' />
                          <p>{trans.home.read_more}</p>
                        </a>
                      </div>
                    </div>

                    <div className="home_so_block">
                      <div data-aos="fade-right">
                        <img src="/assets/img/home/so_b.jpg?t=1" alt="" />
                      </div>
                      <div data-aos="fade-left">
                        <div>
                          <h3>{trans.home.so_t_b}</h3>
                          <div>
                            <a href="/dong-vang-cong-nghe-hanagold" target="_blank">
                              {trans.home.read_more}
                            </a>
                            <img src="/assets/img/home/next_m.svg?t=1" alt={trans.home.so_t_b} className='lazyload' />
                          </div>
                        </div>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: trans.home.so_d_b,
                          }}
                        ></p>
                        <a href="/dong-vang-cong-nghe-hanagold" target="_blank">
                          <img src="/assets/img/home/next.svg" alt="" />
                          <p> {trans.home.read_more}</p>
                        </a>
                      </div>
                    </div>

                    <div className="home_so_block">
                      <div data-aos="fade-right">
                        <img src="/assets/img/home/so_c.jpg?t=1" alt="" />
                      </div>
                      <div data-aos="fade-left">
                        <div>
                          <h3>{trans.home.so_t_c}</h3>
                          <div>
                            <a href="/dai-su-kinh-doanh-vang" target="_blank">
                              {trans.home.read_more}
                            </a>
                            <img src="/assets/img/home/next_m.svg?t=1" alt={trans.home.so_t_c} className='lazyload' />
                          </div>
                        </div>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: trans.home.so_d_c,
                          }}
                        ></p>
                        <a href="/dai-su-kinh-doanh-vang" target="_blank">
                          <img src="/assets/img/home/next.svg" alt="" />
                          <p> {trans.home.read_more}</p>
                        </a>
                      </div>
                    </div>

                    <div className="home_so_block">
                      <div data-aos="fade-right">
                        <img src="/assets/img/home/so_d.jpg?t=1" alt="" />
                      </div>
                      <div data-aos="fade-left">
                        <div>
                          <h3>{trans.home.so_t_d}</h3>
                          <div>
                            <a href="/nhuong-quyen-kinh-doanh-vang" target="_blank">
                              {trans.home.read_more}
                            </a>
                            <img src="/assets/img/home/next_m.svg?t=1" alt={trans.home.so_t_d} />
                          </div>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: trans.home.so_d_d,
                          }}
                        ></p>
                        <a href="/nhuong-quyen-kinh-doanh-vang" target="_blank">
                          <img src="/assets/img/home/next.svg?t=1" alt="" />
                          <p> {trans.home.read_more}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home_achievement">
              <div className="home_achievement_container">
                <div className="home_achievement_container_content">
                  <h2 data-aos="fade-down"> {trans.home.achie}</h2>
                  <p data-aos="fade-down">{trans.home.achie_des}</p>
                  <div>
                    <div
                      className="home_achievement_box"
                      data-aos="fade-down"
                      data-aos-delay="100"
                    >
                      <div className='text-heading'>250K+</div>
                      <p>{trans.home.achie_a}</p>
                    </div>

                    <div
                      className="home_achievement_box"
                      data-aos="fade-down"
                      data-aos-delay="200"
                    >
                      <div className='text-heading'>1.7M USD</div>
                      <p>{trans.home.achie_b}</p>
                    </div>

                    <div
                      className="home_achievement_box"
                      data-aos="fade-down"
                      data-aos-delay="300"
                    >
                      <div className='text-heading'>100K+</div>
                      <p>
                        <p>{trans.home.achie_c}</p>
                      </p>
                    </div>

                    <div
                      className="home_achievement_box"
                      data-aos="fade-down"
                      data-aos-delay="400"
                    >
                      <div className='text-heading'>4+</div>
                      <p>{trans.home.achie_d}</p>
                    </div>

                    <div
                      className="home_achievement_box"
                      data-aos="fade-down"
                      data-aos-delay="500"
                    >
                      <div className='text-heading'>100+</div>
                      <p>{trans.home.achie_e}</p>
                    </div>

                    <div
                      className="home_achievement_box"
                      data-aos="fade-down"
                      data-aos-delay="600"
                    >
                      <div className='text-heading'>50+</div>
                      <p>{trans.home.achie_f}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="c-store-branch__inner">
              <div className="c-store-branch__inner__list">
                <div className={'c-store-branch__inner__list__title'}>
                  <h2>{trans.home.system}</h2>
                </div>
                <div className={'c-store-branch__inner__list__scroll'}>
                  {store.map((item, index) => {
                    return (
                      <div
                        className={
                          selectStore === item.id
                            ? 'c-store-branch__inner__list__scroll__block c-store-branch__inner__list__scroll__active'
                            : 'c-store-branch__inner__list__scroll__block '
                        }
                        onClick={() => onClickStore(item)}
                      >
                        <div className="c-store-branch__inner__list__scroll__block__img">
                          <img
                            src={item.image}
                            alt={item.title}
                            style={{ objectFit: 'cover' }}
                            className='lazyload'
                          />
                        </div>
                        <div className="c-store-branch__inner__list__scroll__block__text h_custom">
                          <div className="h_img_m">
                            <img
                              src={item.image}
                              alt={item.title}
                              className="h_img_mobile lazyload"
                            />
                            <div>
                              <h3>{item.title}</h3>
                              <span>{item.name}</span>
                            </div>
                          </div>
                          <p>{item.address}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="c-store-branch__inner__map mapload">
                <iframe
                  title="store branch"
                  src={urlMap}
                  rel='nofollow'
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  style={{ height: '100%', width: '100%', border: 0 }}
                ></iframe>
              </div>
            </div>

            {partner_list?.length && (
              <div className="partner_h">
                <div className="partner_container">
                  <div className="partner_content">
                    <h2 data-aos="fade-down">{trans.home.partner}</h2>

                    {/**  className="active_partner" */}
                    <div className="partner_header_dt">
                      {partner_list?.map((value, idx) => {
                        return (
                          <div
                            key={idx}
                            className={
                              idx === checkPartner ? 'active_partner' : ''
                            }
                            onClick={() => handleGetPartner(partner_list, idx)}
                          >
                            <p>{value.title}</p>
                          </div>
                        );
                      })}
                    </div>

                    <div className="partner_header_mb">
                      <Slider {...setting_p}>
                        {partner_list?.map((value, idx) => {
                          return (
                            <div key={idx} className="ac_mb">
                              <p>{value.title}</p>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>

                    <div className="partner_body">
                      {listPart?.partner?.map((value, idx) => {
                        return (
                          <div key={idx}>
                            <img src={value?.image} alt="" />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {data_social?.length > 0 && (
              <div className="about_h">
                <div className="about_h_container">
                  <div className="about_h_content">
                    <h2 data-aos="fade-down">{trans.home.about}</h2>
                    <span></span>
                    <div className="about_h_slides" data-aos="fade-down">
                      <Slider {...setting_b}>
                        {data_social?.map((value, idx) => {
                          return (
                            <div className="h_item_slide" key={idx}>
                              <a href={value?.link_url} target="_blank" rel='nofollow'>
                                <img src={value?.image} alt="" />
                              </a>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                    <span></span>
                  </div>
                </div>
              </div>
            )}

            <div className="h_slides_video">
              <div className="h_slides_video_container" data-aos="fade-down">
                <h2>{trans.home.video}</h2>
                <div className="h_slides_video_content">
                  <Slider {...setting_c}>
                    {video?.map((value, idx) => {
                      return (
                        <div className="h_video" key={idx}>
                          {check === idx ? (
                            !value?.thumbnail ? (
                              <iframe
                                ref={ref_play}
                                width="100%"
                                height="512"
                                src={
                                  parseVideo(value?.embed_url || '') +
                                  '?autoplay=1&mute=1'
                                }
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                              ></iframe>
                            ) : value?.isPlay ? (
                              <iframe
                                ref={ref_play}
                                width="100%"
                                height="512"
                                src={
                                  parseVideo(value?.embed_url || '') +
                                  '?autoplay=1&mute=1'
                                }
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                              ></iframe>
                            ) : (
                              <div className="h_thumb">
                                <img
                                  src={value?.thumbnail}
                                  style={{ width: '100%', cursor: 'pointer' }}
                                  alt='thumb video'
                                  className="h_thumb_img lazyload"
                                />

                                <img
                                  src="/assets/img/home/play.svg"
                                  alt=""
                                  className="h_play_ico"
                                  onClick={() => formatVideo(idx)}
                                />
                              </div>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>

            <div className="h_security">
              <div className="h_security_container">
                <div className="h_security_content">
                  <h2 data-aos="fade-down">{trans.home.se}</h2>
                  <div>
                    <div className="h_security_header" data-aos="fade-down">
                      <ul>
                        {listHeader?.map((data, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => selectTab(index)}
                              className={data?.isA ? 'active_li_s' : ''}
                            >
                              {data.title}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {checkC === 0 && (
                      <div className="h_security_body">
                        <div className="h_security_body_r" data-aos="fade-down">
                          <div>
                            <ul>
                              <li>
                                <img src="/assets/img/home/check.svg" alt="" />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: trans.home.se_a,
                                  }}
                                ></p>
                              </li>
                              <li>
                                <img src="/assets/img/home/check.svg" alt="" />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: trans.home.se_b,
                                  }}
                                ></p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="h_security_body_l" data-aos="fade-down">
                          <img src="/assets/img/home/img_s_a.svg?t=1" alt="" />
                        </div>
                      </div>
                    )}

                    {checkC === 1 && (
                      <div className="h_security_body">
                        <div className="h_security_body_r" data-aos="fade-down">
                          <div>
                            <ul>
                              <li>
                                <img src="/assets/img/home/check.svg" alt="" />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: trans.home.se_c,
                                  }}
                                ></p>
                              </li>
                              <li>
                                <img src="/assets/img/home/check.svg" alt="" />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: trans.home.se_d,
                                  }}
                                ></p>
                              </li>

                              <li>
                                <img src="/assets/img/home/check.svg" alt="" />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: trans.home.se_e,
                                  }}
                                ></p>
                              </li>

                              <li>
                                <img src="/assets/img/home/check.svg" alt="" />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: trans.home.se_f,
                                  }}
                                ></p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="h_security_body_l" data-aos="fade-down">
                          <img src="/assets/img/home/img_s_b.svg?t=1" alt="" />
                        </div>
                      </div>
                    )}

                    {checkC === 2 && (
                      <div className="h_security_body">
                        <div className="h_security_body_r" data-aos="fade-down">
                          <div>
                            <ul>
                              <li>
                                <img src="/assets/img/home/check.svg" alt="" />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: trans.home.se_g,
                                  }}
                                ></p>
                              </li>
                              <li>
                                <img src="/assets/img/home/check.svg" alt="" />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: trans.home.se_h,
                                  }}
                                ></p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="h_security_body_l" data-aos="fade-down">
                          <img src="/assets/img/home/img_s_c.svg?t=1" alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <AppDownload is_home={true}/>
            
            <div className="h_news_container">
              <div className="h_news_title">
                <h2 className="h_news_text">{trans.home.news_hng}</h2>
              </div>
              <div className="h_news_list-blog">
                <Slider {...settingNews}>
                  {list_blog?.rows?.map((e, i)=> {
                    return (
                      <div className='h_news_list-blog_block' key={i}>
                        <div className='h_news_list-blog_image'>
                        <a href={`${router.locale === 'en' ? '/en' : ''}/${e?.seo_url}`}><img src={e?.image} className='lazyload'/></a>
                        </div>
                        <div className='h_news_list-blog_title'>
                          <p className='h_news_list-blog_title-category'><a href={`${router.locale === 'en' ? '/en' : ''}/${e?.postcategory?.slug}`}>{e?.postcategory?.name}</a></p>
                          <h3 className='h_news_list-blog_title-blog'><a href={`${router.locale === 'en' ? '/en' : ''}/${e?.seo_url}`}>{e?.title}</a></h3>
                          <p className='h_news_list-blog_title-date'>Ngày đăng: {moment(e?.show_date || e?.createdAt).format('DD/MM/YYYY')}<span>|</span>
                            <span className='h_news_list-blog_title-view'><img src="/assets/img/index/eye.svg" alt="" style={{width: '16px', height: '16px'}}/>{' '}{e?.view}</span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        )}
      </Device>
    </>
  );
}
